import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	VStack,
} from '@tempo/core';

interface SendPasswordLinkModalProps {
	email?: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const SendPasswordLinkModal: React.FC<SendPasswordLinkModalProps> = ({
	email,
	isOpen,
	onClose,
	onConfirm,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				data-testid="send-password-link-modal"
			>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					Send the password renewal link
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					Send a link to the customer so that he can reset his password and set
					a new one.
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<FormControl>
						<FormLabel htmlFor="email" color="text.neutral.primary.default">
							Email address
						</FormLabel>
						<Input id="email" type="email" value={email || ''} disabled />
					</FormControl>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button variant="ghost" mr="spacing.s" onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={onConfirm}>Send the email</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default SendPasswordLinkModal;
