import {Text, Tr, Td} from '@tempo/core';
import React from 'react';

export const TableHeading: React.FC<{children: React.ReactNode}> = ({
	children,
}) => (
	<Tr>
		<Td
			colSpan={2}
			display="flex"
			p="spacing.l"
			alignItems="center"
			gap="spacing.2xl"
			alignSelf="stretch"
			bg="background.neutral.secondary.default"
		>
			<Text
				size="sm"
				color="text.neutral.secondary.default"
				fontSize="var(--Accessibility-12, 12px)"
				fontStyle="normal"
				fontWeight={400}
				lineHeight="var(--Accessibility-16, 16px)"
			>
				{children}
			</Text>
		</Td>
	</Tr>
);
