import React, {useState} from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	VStack,
} from '@tempo/core';
import type {LinkFormData} from '@components/CustomerDetails';
import {validateForceActivationInput} from '@utils/validators';

interface ForceActivationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	linkFormData: LinkFormData | null;
	setLinkFormData: (linkData: LinkFormData) => void;
}

const ForceActivationModal: React.FC<ForceActivationModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	linkFormData,
	setLinkFormData,
}) => {
	const [emailError, setEmailError] = useState<string | null>(null);
	const isEmailEmpty = !linkFormData?.email?.trim();

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value;
		setLinkFormData({...linkFormData, email});

		if (emailError) {
			setEmailError(null);
		}
	};

	const handleActivate = () => {
		const email = linkFormData?.email || '';
		const error = validateForceActivationInput(email.trim());

		if (error) {
			setEmailError(error);
			return;
		}

		setEmailError(null);
		onConfirm();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent padding="spacing.3xl" data-testid="force-activation-modal">
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					Force activation
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					You are about to force manually the link between a subscriber ID and
					an offer. If you prefer to let the user activate his offer by his own,
					send him an activation link.
				</Text>
				<VStack spacing="spacing.xl" mb="spacing.xl">
					<FormControl isRequired isInvalid={!!emailError}>
						<FormLabel htmlFor="email" color="text.neutral.primary.default">
							Email address
						</FormLabel>
						<Input
							id="email"
							type="email"
							value={linkFormData?.email || ''}
							onChange={handleEmailChange}
						/>
						{emailError && (
							<FormErrorMessage data-testid="email-error">
								{emailError}
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isRequired>
						<FormLabel
							htmlFor="subscriptionId"
							color="text.neutral.primary.default"
						>
							Subscriber ID
						</FormLabel>
						<Input
							id="subscriptionId"
							value={linkFormData?.subscriptionId}
							disabled
						/>
						<FormHelperText
							color="text.neutral.secondary.default"
							fontSize="var(--Accessibility-12, 12px)"
						>
							Subscriber ID, MSISDN, code...
						</FormHelperText>
					</FormControl>
					<Text
						alignSelf="flex-end"
						color="text.neutral.secondary.default"
						fontSize="var(--Accessibility-14, 14px)"
					>
						<span style={{color: 'var(--tempo-colors-red-500)'}}>* </span>
						<span>Mandatory field</span>
					</Text>
				</VStack>
				<Box display="flex" justifyContent="end">
					<Button variant="ghost" mr="spacing.s" onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={handleActivate} isDisabled={isEmailEmpty}>
						Activate
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ForceActivationModal;
