import React, {useState} from 'react';
import {useDisclosure} from '@tempo/core';
import UnlinkModal from '@components/Modals/UnlinkModal';
import ConfirmationModal from '@components/Modals/ConfirmationModal';
import ErrorModal from '@components/Modals/ErrorModal';
import ForceActivationModal from '@components/Modals/ForceActivationModal';
import CustomerDetailsTable from '@components/CustomerDetailsTable';
import CopyPasswordLinkModal from '@components/Modals/CopyPasswordLinkModal';
import SendPasswordLinkModal from '@components/Modals/SendPasswordLinkModal';
import type {CustomerDetailsProps} from '@components/CustomerDetailsTable';

type CustomerDetailsCallback = {
	onCustomerUpdated: () => Promise<void>;
};

export interface LinkFormData {
	email?: string;
	subscriptionId?: string;
}

const CustomerDetails: React.FC<
	CustomerDetailsProps & CustomerDetailsCallback
> = ({data, included, onCustomerUpdated}) => {
	const [linkFormData, setLinkFormData] = useState<LinkFormData>({
		email: '',
		subscriptionId: data.attributes._id,
	});
	const [resetPasswordLink, setResetPasswordLink] = useState<string>('');
	const email = included?.find((i) => i.type === 'User')?.attributes.email;

	const {
		isOpen: isUnlinkModalOpen,
		onOpen: onUnlinkModalOpen,
		onClose: onUnlinkModalClose,
	} = useDisclosure();
	const {
		isOpen: isUnlinkConfirmationModalOpen,
		onOpen: onUnlinkConfirmationModalOpen,
		onClose: onUnlinkConfirmationModalClose,
	} = useDisclosure();
	const {
		isOpen: isUnlinkErrorModalOpen,
		onOpen: onUnlinkErrorModalOpen,
		onClose: onUnlinkErrorModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationModalOpen,
		onOpen: onForceActivationModalOpen,
		onClose: onForceActivationModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationConfirmationModalOpen,
		onOpen: onForceActivationConfirmationModalOpen,
		onClose: onForceActivationConfirmationModalClose,
	} = useDisclosure();
	const {
		isOpen: isForceActivationErrorModalOpen,
		onOpen: onForceActivationErrorModalOpen,
		onClose: onForceActivationErrorModalClose,
	} = useDisclosure();
	const {
		isOpen: isCopyPasswordLinkModalOpen,
		onOpen: onCopyPasswordLinkModalOpen,
		onClose: onCopyPasswordLinkModalClose,
	} = useDisclosure();
	const {
		isOpen: isSendPasswordLinkModalOpen,
		onOpen: onSendPasswordLinkModalOpen,
		onClose: onSendPasswordLinkModalClose,
	} = useDisclosure();
	const {
		isOpen: isSendPasswordLinkConfirmationModalOpen,
		onOpen: onSendPasswordLinkConfirmationModalOpen,
		onClose: onSendPasswordLinkConfirmationModalClose,
	} = useDisclosure();

	const handleUnlink = async () => {
		onUnlinkModalClose();
		onUnlinkErrorModalClose();
		try {
			const response = await fetch(
				`/api/unlink?subscriptionId=${data.attributes._id}`,
			);

			if (!response.ok) {
				throw new Error('Unlink failed');
			}

			await response.json();
			await onCustomerUpdated();
			onUnlinkConfirmationModalOpen();
		} catch (error) {
			onUnlinkErrorModalOpen();
		}
	};

	const handleForceActivation = async () => {
		onForceActivationModalClose();
		onForceActivationErrorModalClose();
		try {
			const response = await fetch(
				`/api/force-activation?subscriptionId=${data.attributes._id}&email=${linkFormData.email}`,
			);

			if (!response.ok) {
				throw new Error('Force activation failed');
			}

			await response.json();
			await onCustomerUpdated();
			onForceActivationConfirmationModalOpen();
		} catch (error) {
			onForceActivationErrorModalOpen();
		}
	};

	const handleSendPasswordLink = async () => {
		onSendPasswordLinkModalClose();

		const response = await fetch('/api/resetpassword', {
			method: 'POST',
			body: JSON.stringify({email}),
		});

		if (!response.ok) {
			throw new Error('Send password link failed');
		}

		onSendPasswordLinkConfirmationModalOpen();
	};

	const handleCopyPasswordLinkModalOpen = async () => {
		const response = await fetch(`/api/resetpassword?email=${email}`);

		if (!response.ok) {
			throw new Error('Fetch reset password link failed');
		}

		const data = await response.json();
		const link = data.data.attributes.recoveryPasswordLink;
		setResetPasswordLink(link);
		onCopyPasswordLinkModalOpen();
	};

	const UnlinkConfirmationModal = () => (
		<ConfirmationModal
			isOpen={isUnlinkConfirmationModalOpen}
			onClose={onUnlinkConfirmationModalClose}
			title="Account unlinked"
			subtitle="The subscriber ID has been unlinked from the previous offer."
		/>
	);

	const ForceActivationConfirmationModal = () => (
		<ConfirmationModal
			isOpen={isForceActivationConfirmationModalOpen}
			onClose={onForceActivationConfirmationModalClose}
			title="Activation correctly done"
			subtitle="The subscriber ID has been linked to the offer."
		/>
	);

	const UnlinkErrorModal = () => (
		<ErrorModal
			isOpen={isUnlinkErrorModalOpen}
			onClose={onUnlinkErrorModalClose}
			onRetry={handleUnlink}
			title="The account has not been unlinked"
		/>
	);

	const ForceActivationErrorModal = () => (
		<ErrorModal
			isOpen={isForceActivationErrorModalOpen}
			onClose={onForceActivationErrorModalClose}
			onRetry={handleForceActivation}
			title="The activation failed"
		/>
	);
	const SendPasswordLinkConfirmationModal = () => (
		<ConfirmationModal
			isOpen={isSendPasswordLinkConfirmationModalOpen}
			onClose={onSendPasswordLinkConfirmationModalClose}
			title="Link correctly sent"
			subtitle="It could take a few minutes for the customer to it."
		/>
	);

	return (
		<>
			<UnlinkModal
				isOpen={isUnlinkModalOpen}
				onClose={onUnlinkModalClose}
				onConfirm={handleUnlink}
			/>
			<UnlinkConfirmationModal />
			<UnlinkErrorModal />
			<ForceActivationModal
				isOpen={isForceActivationModalOpen}
				onClose={onForceActivationModalClose}
				onConfirm={handleForceActivation}
				linkFormData={linkFormData}
				setLinkFormData={setLinkFormData}
			/>
			<ForceActivationConfirmationModal />
			<ForceActivationErrorModal />
			<SendPasswordLinkModal
				email={email}
				isOpen={isSendPasswordLinkModalOpen}
				onClose={onSendPasswordLinkModalClose}
				onConfirm={handleSendPasswordLink}
			/>
			<SendPasswordLinkConfirmationModal />
			<CopyPasswordLinkModal
				link={resetPasswordLink}
				isOpen={isCopyPasswordLinkModalOpen}
				onClose={onCopyPasswordLinkModalClose}
			/>
			<CustomerDetailsTable
				data={data}
				included={included}
				openForceActivationModal={onForceActivationModalOpen}
				openUnlinkModal={onUnlinkModalOpen}
				openSendPasswordLinkModal={onSendPasswordLinkModalOpen}
				openCopyPasswordLinkModal={handleCopyPasswordLinkModalOpen}
			/>
		</>
	);
};

export default CustomerDetails;
